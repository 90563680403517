.container {
  height: calc(100% - 70px);
  background: #fff;
  margin: 10px 20px;
  border-radius: 10px;
  padding-top: 30px;
}

.main {
  display: flex;
  justify-content: space-between;
  height: 98%;
  padding-bottom: 20px;
  overflow-y: auto;
}

.left {
  width: 65%;
}

.upload {
  display: flex;
  /* align-items: flex-start; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  align-items: center;

}

.uploadButton {
  /* width: 60px;
  height: 60px; */
  width: 88px;
  height: 32px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
}

.uploadButtonImg {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.uploadText {
  font-size: 14px;
  color: #d1d0d1;
}

.imgWrap {
  position: relative;
  margin: 10px 10px 0px 10px;
  width: 100px;
  height: 100px;
  user-select: none;
}

.img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.imgClose {
  position: absolute;
  right: -9px;
  top: -10px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  color: #fff;
  background-color: #999999;
  border-radius: 50%;
  cursor: pointer;
}

.labelColor {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #ffeed8;
  padding: 0 10px;
  margin-right: 10px;
  color: #fc9c41;
  margin-bottom: 5px;
  position: relative;
}

.closeIcon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  right: -7px;
  top: -4px;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typeSettingModal :global(.ant-modal-body) {
  padding: 10px 20px !important;
  height: 420px !important;
  overflow: hidden !important;
}

.addPrescriptionModal :global(.ant-modal-body) {
  height: 640px !important;
  overflow: hidden !important;
  padding: 10px 15px !important;
}

.addPrescriptionModal :global(.ant-collapse-header) {
  padding:9px 40px 9px 16px !important;
  background-color: #fff !important;
}

.addPrescriptionModal :global(.ant-collapse-item) {
  margin-bottom:0px !important;
}

.addPrescriptionModal :global(.ant-collapse-content > .ant-collapse-content-box) {
  padding:10px !important;
}

.buttonMain{
  display: flex;
  justify-content: center;
}

.addPrescriptionModal :global(.ant-collapse){
 margin-bottom: 10px;
}
