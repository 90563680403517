.container {
  height: calc(100% - 30px);
  background: #fff;
  margin: 10px 0;
  border-radius: 10px;
}

.detailModal :global(.ant-modal-body) {
  height: 400px !important;
  overflow: auto !important;
}
