.rejectModalMain :global(textarea.ant-input) {
  min-height: 120px !important;
}

.extractionOfARootModal :global(.ant-modal-body) {
  height: 600px !important;
  overflow: hidden !important;
  padding: 10px 15px !important;
}

.extractionOfARootModal :global(.ant-collapse-header) {
  padding: 9px 40px 9px 16px !important;
  background-color: #fff !important;
}

.extractionOfARootModal :global(.ant-collapse-item) {
  margin-bottom: 0px !important;
}

.extractionOfARootModal
  :global(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 10px !important;
}

.extractionOfARootModal :global(.ant-collapse){
  margin-bottom: 10px;
 }

.tableMain {
  background-color: #f7f7f7;
  padding: 0 10px 10px 10px ;
}

.tableMainTitle {
  padding: 10px 0;
}

.detailModal :global(.ant-modal-body) {
  height: 400px !important;
  overflow: auto !important;
}

.detailModal :global(.ant-collapse-header) {
  padding: 9px 40px 9px 16px !important;
  background-color: #fff !important;
}

.detailModal :global(.ant-collapse-item) {
  margin-bottom: 0px !important;
}

.detailModal
  :global(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 10px !important;
}

