.modal {
  padding-left: 15px;
}

.mb_10 {
  margin-bottom: 10px;
}

.colorff {
  font-weight: bold;
  color: #ff1b1b;
  font-size: 16px;
}

.orderDetailMain :global(.ant-modal-body) {
  height: 500px !important;
  overflow: auto !important;
}

.tableContent {
  margin-bottom: 20px;
}
